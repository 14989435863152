import React from 'react';
import { ContentPageContainer } from '../../components/Common/Page/styles';
import * as Styled from './styles';
import PageTitle from '../../components/Common/PageTitle';
import TermsText from './sections/TermsText';
import Enter from './sections/Enter';
import Prizes from './sections/Prizes';
import Competition from './sections/Competition';
import Fixture from './sections/Fixture';
import Winners from './sections/Winners';
import Publicity from './sections/Publicity';
import Limitation from './sections/Limitation';
import Raf from './sections/Raf';
import Euros from './sections/Euros';

const Terms = () => (
    <ContentPageContainer>
        <PageTitle>Terms & Conditions</PageTitle>
        <Styled.ContentContainer data-test-id="t_and_c_page_content">
            <Styled.OrderedList>
                <TermsText />
                <Enter />
                <Competition />
                <Prizes />
                <Fixture />
                <Winners />
                <Publicity />
                <Limitation />
                <Raf />
                <Euros />
            </Styled.OrderedList>
        </Styled.ContentContainer>
    </ContentPageContainer>
);
export default Terms;
