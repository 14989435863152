export const IMAGE_BASE_PATH =
    window.env && window.env.IMAGE_BASE_PATH
        ? window.env.IMAGE_BASE_PATH
        : process.env.REACT_APP_IMAGE_BASE_PATH;

export const FONT_BASE_PATH =
    window.env && window.env.FONT_BASE_PATH
        ? window.env.FONT_BASE_PATH
        : '/fonts';

export const API_ROOT = () => process.env.REACT_APP_API_ROOT;

export const OAUTH_URI =
    window.env && window.env.OAUTH_BASE_URI
        ? window.env.OAUTH_BASE_URI
        : process.env.REACT_APP_OAUTH_BASE_URI;

export const OAUTH_CALLBACK_URI =
    window.env && window.env.OAUTH_CALLBACK_URI
        ? window.env.OAUTH_CALLBACK_URI
        : process.env.REACT_APP_OAUTH_CALLBACK_URI;

export const SSO_CONSUMER_URL =
    window.env && window.env.SSO_CONSUMER_URL
        ? window.env.SSO_CONSUMER_URL
        : process.env.REACT_APP_SSO_CONSUMER_URL;
export const SSO_AUTH_HOST =
    window.env && window.env.SSO_AUTH_HOST
        ? window.env.SSO_AUTH_HOST
        : process.env.REACT_APP_SSO_AUTH_HOST;
export const SSO_CONSUMER =
    window.env && window.env.SSO_CONSUMER
        ? window.env.SSO_CONSUMER
        : process.env.REACT_APP_SSO_CONSUMER;
export const NOTIFICATION_ENV =
    window.env && window.env.NOTIFICATION_ENV
        ? window.env.NOTIFICATION_ENV
        : process.env.REACT_APP_NOTIFICATION_ENV;

export const SUPER6_EXTRA_API_ROOT =
    process.env.REACT_APP_SUPER6_EXTRA_API_ROOT ||
    'https://api.s6e.sbgservices.com/api';

export const USE_LEADERBOARD_API =
    'REACT_APP_USE_LEADERBOARD_API' in process.env
        ? Boolean(+process.env.REACT_APP_USE_LEADERBOARD_API)
        : true;

export const LCP_METRICS_PERCENTAGE =
    'REACT_APP_LCP_METRICS_PERCENTAGE' in process.env
        ? Number(process.env.REACT_APP_LCP_METRICS_PERCENTAGE)
        : 0.01;

const getEnv = variable => {
    const value = process.env[variable];

    if (!value) {
        throw new Error(`Environment variable not set: ${variable}`);
    }

    return value;
};

export const generateGoogleAnalyticsURL = (i, dl) =>
    `https://www.googletagmanager.com/gtm.js?id=${i}${dl}${process.env
        .REACT_APP_GA_URL ?? ''}`;

export const ONE_TRUST_WRAPPER_URL =
    process.env.REACT_APP_ONE_TRUST_WRAPPER_URL;

export const ONE_TRUST_DATA_DOMAIN_SCRIPT =
    process.env.REACT_APP_ONE_TRUST_DATA_DOMAIN_SCRIPT;

export const ONE_TRUST_AUTO_BLOCKER_URL =
    process.env.REACT_APP_ONE_TRUST_AUTO_BLOCKER_URL;

export default {
    getS3BucketPath: () => getEnv('REACT_APP_S3_CONTENT_BUCKET')
};
