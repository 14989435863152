import BannerEvent from './bannerEvent';
import NavigationEvent from './navigationEvent';
import InterfaceEvent from './interfaceEvent';
import { setUserProperty, logEvent } from './event_bridge';
import {
    generateGoogleAnalyticsURL,
    ONE_TRUST_WRAPPER_URL,
    ONE_TRUST_DATA_DOMAIN_SCRIPT,
    ONE_TRUST_AUTO_BLOCKER_URL
} from '../../config';

export const sendBannerEvent = (
    elementText,
    module,
    action,
    position,
    destinationUrl
) => {
    const bannerEvent = new BannerEvent(
        elementText,
        module,
        action,
        position,
        destinationUrl
    );
    bannerEvent.send('banner');
};

export const sendNavigationEvent = (
    module,
    moduleDisplayOrder,
    elementText,
    position,
    destinationUrl,
    eventContext
) => {
    const navigationEvent = new NavigationEvent(
        module,
        moduleDisplayOrder,
        elementText,
        position,
        destinationUrl,
        eventContext
    );
    navigationEvent.send('navigation');
};

export const sendInterfaceEvent = (
    elementText,
    action,
    module,
    eventContext
) => {
    const interfaceEvent = new InterfaceEvent(
        elementText,
        action,
        module,
        eventContext
    );
    interfaceEvent.send('interface');
};

const filterAppsKeys = metadata => {
    const propsToSendToNative = [
        'brand',
        'context',
        'jurisdiction',
        'language',
        'locale',
        'vertical',
        'account_id',
        'currency',
        'login_status',
        'reg_status',
        'user_id'
    ];
    const keys = Object.keys(metadata);
    const filteredKeys = keys.filter(key => propsToSendToNative.includes(key));

    return filteredKeys;
};

// Script taken from https://developers.google.com/tag-platform/devguides/datalayer
function initialiseGoogleTagManager() {
    const scripts = [...document.getElementsByTagName('script')];

    const gtmNotIncluded =
        scripts.filter(script => script.src.includes('googletagmanager'))
            .length === 0;

    if (gtmNotIncluded && scripts.length > 0) {
        // eslint-disable-next-line func-names
        (function(w, d, s, l, i) {
            // eslint-disable-next-line no-param-reassign
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            // eslint-disable-next-line one-var, vars-on-top, no-var
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                // eslint-disable-next-line prefer-template, eqeqeq
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = generateGoogleAnalyticsURL(i, dl);
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-57TQ96RL');
    }
}

function initialiseOneTrust() {
    const scripts = [...document.getElementsByTagName('script')];

    const oneTrustNotIncluded =
        scripts.filter(script => script.id.includes('onetrust')).length === 0;

    if (oneTrustNotIncluded) {
        // OneTrust Wrapper URL
        const oneTrustWrapperURLScript = document.createElement('script');
        oneTrustWrapperURLScript.defer = true;
        oneTrustWrapperURLScript.type = 'text/javascript';
        oneTrustWrapperURLScript.src = ONE_TRUST_WRAPPER_URL;
        oneTrustWrapperURLScript.id = 'onetrust_wrapper_url';
        oneTrustWrapperURLScript.setAttribute(
            'data-domain-script',
            ONE_TRUST_DATA_DOMAIN_SCRIPT
        );
        document.body.appendChild(oneTrustWrapperURLScript);

        // OneTrust Auto Blocker URL
        const oneTrustAutoBlockerURLScript = document.createElement('script');
        oneTrustAutoBlockerURLScript.defer = true;
        oneTrustAutoBlockerURLScript.src = ONE_TRUST_AUTO_BLOCKER_URL;
        oneTrustAutoBlockerURLScript.id = 'onetrust_auto_blocker_url';
        document.body.appendChild(oneTrustAutoBlockerURLScript);
    }
}

export const sendMetadataEvent = metadata => {
    window.dataLayer = window.dataLayer || [];
    if (metadata.account_id) {
        if (window.Android || window.webkit) {
            filterAppsKeys(metadata).forEach(key =>
                setUserProperty(key, metadata[key])
            );
        } else {
            window.dataLayer.push({ event: 'metaData', ...metadata });
            initialiseGoogleTagManager();
        }
        initialiseOneTrust();
    }
};

let currentPage = '';

export const sendPageView = pageTitle => {
    window.dataLayer = window.dataLayer || [];
    if (pageTitle !== currentPage && (window.Android || window.webkit)) {
        logEvent('screen_view', { screen_name: pageTitle });
        currentPage = pageTitle;
    }
};
