import React from 'react';
import PropTypes from 'prop-types';
import StreakBox from '../components/StreakBox';
import StatsBoxGroup from '../components/StatsBoxGroup';
import LastRound from '../../../components/LastRound';
import Graph from '../components/Graph';
import { IMAGE_BASE_PATH } from '../../../config';
import * as Styled from './styles';

const PerformanceHub = ({
    selfStats,
    seasonStats,
    streak,
    subText,
    buttonText,
    buttonLink,
    hasEntered,
    isLive,
    brokenStreak,
    buttonOnClick,
    componentsEnabled,
    lastRoundData,
    h2hText,
    h2hEnabled,
    entryData
}) => (
    <>
        <Styled.TitleBox>
            <Styled.MyPerformanceHubTitle>
                <img
                    src={`${IMAGE_BASE_PATH}/img/performance-hub/logo.svg`}
                    alt="My Performance hub"
                />
            </Styled.MyPerformanceHubTitle>
        </Styled.TitleBox>
        <Styled.PerformanceHubContainer componentsEnabled={componentsEnabled}>
            <Styled.PerformanceHubDataContainer>
                <Styled.StreakBoxContainer>
                    <StreakBox
                        streak={streak}
                        subText={subText}
                        buttonText={buttonText}
                        buttonLink={buttonLink}
                        hasEntered={hasEntered}
                        isLive={isLive}
                        brokenStreak={brokenStreak}
                        buttonOnClick={buttonOnClick}
                    />
                </Styled.StreakBoxContainer>
                <Styled.StatsBoxGroupContainer>
                    <StatsBoxGroup
                        seasonStats={seasonStats}
                        selfStats={selfStats}
                    />
                </Styled.StatsBoxGroupContainer>
            </Styled.PerformanceHubDataContainer>
            {componentsEnabled && (
                <Styled.PerformanceHubComponentContainer>
                    {lastRoundData && (
                        <LastRound
                            totalPoints={lastRoundData.totalPoints}
                            fixtures={lastRoundData.fixtures}
                            h2hText={h2hEnabled ? h2hText : ''}
                        />
                    )}
                    {entryData && <Graph entryData={entryData} />}
                </Styled.PerformanceHubComponentContainer>
            )}
        </Styled.PerformanceHubContainer>
    </>
);

PerformanceHub.propTypes = {
    selfStats: PropTypes.shape({
        correctScores: PropTypes.number,
        correctResults: PropTypes.number,
        totalPoints: PropTypes.number,
        currentStreak: PropTypes.number,
        currentStreakAccumulatedPoints: PropTypes.number,
        averagePoints: PropTypes.number,
        roundsEntered: PropTypes.number,
        bestStreak: PropTypes.number
    }).isRequired,
    seasonStats: PropTypes.shape({
        currentTopPercentage: PropTypes.number
    }).isRequired,
    streak: PropTypes.number.isRequired,
    subText: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    buttonOnClick: PropTypes.func,
    hasEntered: PropTypes.bool,
    isLive: PropTypes.bool,
    brokenStreak: PropTypes.bool,
    componentsEnabled: PropTypes.bool,
    lastRoundData: PropTypes.shape({
        totalPoints: PropTypes.number.isRequired,
        fixtures: PropTypes.arrayOf(
            PropTypes.shape({
                points: PropTypes.number,
                homeTeam: PropTypes.shape({
                    badgeUri: PropTypes.string
                }).isRequired,
                awayTeam: PropTypes.shape({
                    badgeUri: PropTypes.string
                }).isRequired,
                predictedHomeScore: PropTypes.number,
                homeScore: PropTypes.number,
                predictedAwayScore: PropTypes.number,
                awayScore: PropTypes.number,
                isAbandoned: PropTypes.bool
            })
        ).isRequired
    }),
    h2hText: PropTypes.string,
    headToHeadSeasonStats: PropTypes.shape({
        scores: PropTypes.number,
        results: PropTypes.number,
        points: PropTypes.number,
        userBeatOpponent: PropTypes.number,
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profileImage: PropTypes.string,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    }),
    h2hEnabled: PropTypes.bool,
    entryData: PropTypes.arrayOf(
        PropTypes.shape({
            roundId: PropTypes.number.isRequired,
            totalPoints: PropTypes.number,
            didEnter: PropTypes.bool.isRequired
        })
    )
};

PerformanceHub.defaultProps = {
    subText: null,
    hasEntered: false,
    isLive: false,
    brokenStreak: false,
    buttonOnClick: undefined,
    componentsEnabled: false,
    lastRoundData: null,
    h2hText: null,
    headToHeadSeasonStats: null,
    h2hEnabled: true,
    entryData: null
};

export default PerformanceHub;
