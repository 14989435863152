import React from 'react';
import * as Styled from '../styles';

const Euros = () => (
    <>
        <li>
            Jackpot Competition &#45; Super 6 On Tour
            <Styled.OrderedList>
                <li>
                    The Jackpot Competition prize is &#163;250,000 unless
                    otherwise specified &#40;
                    <strong>&quot;Jackpot Competition Prize&quot;</strong>&#41;
                    and the winner will be determined as follows:
                    <Styled.OrderedList>
                        <li>
                            The Jackpot Competition Prize is awarded to the
                            player who correctly predicts the scores in all six
                            Super 6 fixtures in a relevant round.
                        </li>
                        <li>
                            If there are two or more players who have correctly
                            predicted the score of all six Super 6 fixtures, the
                            Jackpot Competition Prize will be won by the player
                            whose Golden Goal Prediction is closest to the
                            actual time of the first goal.
                        </li>
                        <li>
                            If two or more players correctly predicted the score
                            of all six Super 6 fixtures and their Golden Goal
                            Predictions are equally accurate, the Jackpot
                            Competition Prize will be split equally amongst
                            those players.
                        </li>
                        <li>
                            Between June 14th and July 5th Super 6 will be
                            running a competition for the Euro 2024 tournament
                            called Super 6 On Tour &#40;
                            <strong>&quot;Super 6 On Tour&quot;</strong>&#41;
                            where the Jackpot Competition Prize will be
                            &#163;100,000.
                        </li>
                    </Styled.OrderedList>
                </li>
            </Styled.OrderedList>
        </li>
        <li>
            Top Scorer Prize &#45; Super 6 On Tour
            <Styled.OrderedList>
                <li>
                    Significant Conditions:
                    <Styled.UnorderedList>
                        <li>18+</li>
                        <li>Eligibility Restrictions</li>
                        <li>Prizes will vary</li>
                        <li>T&amp;Cs apply</li>
                    </Styled.UnorderedList>
                </li>
                <li>
                    Subject to the provisions below, the top scorer prize for
                    each round is &#163;1,000 unless otherwise specified &#40;
                    <strong>&quot;Top Scorer Prize&quot;</strong>&#41; and shall
                    be determined as follows:
                    <Styled.OrderedList>
                        <li>
                            The Top Scorer Prize is awarded where there are no
                            players who correctly predict the scores in all six
                            Super 6 fixtures in a relevant round and no Jackpot
                            Competition Prize is awarded.
                        </li>
                        <li>
                            The Top Scorer Prize will be awarded to the player
                            who scores the highest number of points in a round
                            &#40;
                            <strong>
                                &quot;Round Competition Winner&quot;
                            </strong>
                            &#41;.
                        </li>
                        <li>
                            At least four or more Super 6 fixtures must be
                            completed during the relevant round in order for a
                            Top Scorer Prize for that round to be awarded.
                        </li>
                        <li>
                            If two or more players have the same number of
                            points, the winner will be the player whose Golden
                            Goal Prediction is closest to the actual time of the
                            first goal.
                        </li>
                        <li>
                            If two or more players Golden Goal Predictions from
                            Clause 6.8.4 were equally close to the time of the
                            first goal, the prize for that round will be split
                            equally amongst those players.
                        </li>
                        <li>
                            An additional bonus of &#163;1,000 &#40;unless
                            advertised otherwise&#41; &#40;
                            <strong>&quot;Get 5 Bonus&quot;</strong>
                            &#41; will be awarded if the Round Competition
                            Winner has correctly predicted the scores for five
                            of the Super 6 fixtures that round.
                        </li>
                        <li>
                            In the case of ties as referred to clause 6.8.5
                            above, where the tied entrants each correctly
                            predict the scores for five of the Super 6 fixtures
                            and have equally close Golden Goal Predictions, the
                            Get 5 Bonus will be split equally between all
                            winners of the round.
                        </li>
                        <li>
                            During Super 6 On Tour, the Top Scorer prize for
                            each round taking place between June 14th and July
                            5th will be &#163;1,000. There will be no “Get 5
                            Bonus” awarded during this period.
                        </li>
                    </Styled.OrderedList>
                </li>
            </Styled.OrderedList>
        </li>
        <li>
            Head to Head Challenge &#45; Super 6 On Tour
            <Styled.OrderedList>
                <li>
                    Significant Conditions:
                    <Styled.UnorderedList>
                        <li>18+</li>
                        <li>Eligibility Restrictions</li>
                        <li>Prizes will vary</li>
                        <li>T&amp;Cs apply</li>
                    </Styled.UnorderedList>
                </li>
                <li>
                    Players are automatically opted into a Head to Head
                    challenge via the Website or Mobile Apps prior to the
                    deadline for submitting entries for that round &#40;
                    <strong>&quot;Head to Head Challenge&quot;</strong>&#41;.
                </li>
                <li>
                    Once predictions have been submitted for that round, players
                    shall automatically gain entry into the Head to Head
                    Challenge prize draw if they obtain a higher number of
                    points in any round &#40;calculated as per clause 5.4&#41;
                    than the Head to Head opponent for that round.
                </li>
                <li>
                    The Head to Head Challenge Prizes shall be awarded to
                    players who are placed into the Head to Head Challenge prize
                    draw and selected at random by a computer program shortly
                    after the completion of a round.
                </li>
                <li>
                    Head to Head Challenge Prizes will be credited within four
                    weeks of verification of the winning player in accordance
                    with the verification process detailed in clause 9
                    &#40;Winners and Entrants Verification&#41; below.
                </li>
                <li>
                    In the event that a player&apos;s Head to Head Challenge is
                    terminated due to a decision by the Promoter pursuant to
                    clause 6.16 of these Terms and Conditions, each player to
                    the relevant Head to Head Challenge will be removed from the
                    Head to Head Challenge.
                </li>
                <li>
                    The Head to Head opponent may change their predictions
                    before the closing time for a round via the Website or
                    Mobile Apps. Once a round has closed for entries, the Head
                    to Head opponent shall not change their predictions.
                </li>
                <li>
                    In the event there is no submission of the Head to Head
                    opponent&apos;s predictions for a round, the Head to Head
                    Challenge shall be deemed void for that round.
                </li>
                <li>
                    The Promoter reserves the right to suspend, modify, remove
                    and/or add a Head to Head Challenge &#40;including the Head
                    to Head Challenge Prizes&#41; at its sole discretion with
                    immediate effect and without notice.
                </li>
                <li>
                    During Super 6 On Tour, The Head to Head Challenge
                    Prize/Prizes will be won by one player who can outscore the
                    Head to Head opponent in that specific round and could
                    consist of cash or non&#45;cash prizes. The value of any
                    cash prize shall never be lower than &#163;100.
                </li>
                <li>
                    During Super 6 On Tour, the guaranteed prizes available for
                    each round are displayed below:
                    <Styled.OrderedList>
                        <li>Round One &#45; &#163;20,000</li>
                        <li>Round Two &#45; &#163;10,000</li>
                        <li>Round Three &#45; &#163;10,000</li>
                        <li>Round Four &#45; &#163;10,000</li>
                    </Styled.OrderedList>
                </li>
            </Styled.OrderedList>
        </li>
        <li>
            The 100 Club &#45; Super 6 On Tour
            <Styled.OrderedList>
                <li>
                    Significant Conditions:
                    <Styled.UnorderedList>
                        <li>18+</li>
                        <li>
                            Predict a score whereby 100 players or less predict
                            the correct score from any Super 6 fixture.
                        </li>
                        <li>Prizes will vary</li>
                        <li>Eligibility Restrictions</li>
                        <li>T&amp;Cs apply</li>
                    </Styled.UnorderedList>
                </li>
                <li>
                    To be eligible to win a prize as part of this competition,
                    you must be aged 18 years and over and registered as a
                    customer of Sky Betting &amp; Gaming. By playing any Super 6
                    round you opt into the chance of winning this prize. Round
                    schedules can be found on the app or website and are at the
                    discretion of the Promoter.
                </li>
                <li>
                    This competition will run from Friday 28 July 2023 at 12:00
                    until May 31 2025 at 23:59pm &#40;the &quot;Promotional
                    Period&quot;&#41;. Any entries made outside of the
                    Promotional Period will not qualify.
                </li>
                <li>
                    To win you must predict a score whereby 100 players or less
                    predict the correct score from any Super 6 fixture in a
                    given round. This prize will be available every round.
                </li>
                <li>
                    The prize available to players is at the discretion of Super
                    6 but will be identical for all winners within the same
                    round &#40;the &quot;Prize&quot;&#41;. This prize is
                    available in every round of Super 6 between Friday 28 July
                    2023 at 12:00 until May 31 2025 23:59. We reserve the right
                    to credit the prize in cash. For players using Euros, all
                    prizes will be at an exchange rate of &#163;1:&#8364;1.
                </li>
                <li>
                    Winners will be contacted no later than two weeks following
                    the end of the Super 6 round containing the correct
                    prediction.
                </li>
                <li>
                    The winner will be notified by email to the email address
                    registered on their account. Customers are responsible for
                    ensuring the contact details on their account are up to
                    date.
                </li>
                <li>
                    If the Promoter has been unable to contact the winner of the
                    Prize within 14 days of that game period or competition
                    ending the winner shall forfeit the prize and the Promoter
                    can expire the prize.
                </li>
                <li>
                    Once a winner has responded to the Promoter and verified
                    their details to the Promoter&apos;s satisfaction, the
                    winner will receive their prize within 28 days.
                </li>
                <li>
                    No entries on behalf of any third parties shall be accepted.
                </li>
                <li>
                    This competition is available through the Super 6 website
                    page only.
                </li>
                <li>
                    The Promoter has the right, at their reasonable discretion,
                    to alter, amend or terminate this competition acting
                    reasonably and with prior notice.
                </li>
                <li>
                    If any provision or part&#45;provision of these terms and
                    conditions are or becomes invalid, illegal or unenforceable,
                    it shall be deemed modified to the minimum extent necessary
                    to make it valid, legal and enforceable. If such
                    modification is not possible, the relevant provision or
                    part&#45;provision shall be deemed deleted. Any modification
                    to or deletion of a provision or part&#45;provision under
                    this clause shall not affect the validity and enforceability
                    of the rest of these terms and conditions.
                </li>
                <li>
                    Promoter is Hestview Limited &#40;trading as Sky Games&#41;
                    with a registered office address of One Chamberlain Square
                    Cs, Birmingham, United Kingdom, B3 3AX.
                </li>
            </Styled.OrderedList>
        </li>
        <li>
            Build your Streak &#45; Super 6 On Tour
            <Styled.OrderedList>
                <li>
                    Significant Conditions:
                    <Styled.UnorderedList>
                        <li>18+</li>
                        <li>Eligibility Restrictions</li>
                        <li>Prizes will vary</li>
                        <li>T&amp;Cs apply</li>
                    </Styled.UnorderedList>
                </li>
                <li>
                    This competition will only be available for the duration of
                    Super 6 On Tour.
                </li>
                <li>
                    Play consecutive rounds of Super 6 On Tour for a chance to
                    win prizes throughout the tournament. You can win a prize
                    for playing two, three or four consecutive rounds across the
                    tournament.
                </li>
                <li>
                    There will be a prize draw after rounds two, three and four,
                    relating to the streak a player has built throughout the
                    tournament.
                    <Styled.OrderedList>
                        <li>
                            Round Two Prize Draw &#40;x1 prize available&#41;
                            <Styled.OrderedList>
                                <li>
                                    Each player who has entered the opening two
                                    rounds of Super 6 On Tour.
                                </li>
                            </Styled.OrderedList>
                        </li>
                        <li>
                            Round Three Prize Draw &#40;x2 prizes available&#41;
                            <Styled.OrderedList>
                                <li>
                                    Each player who has entered the opening
                                    three rounds of Super 6 On Tour.
                                </li>
                                <li>
                                    Each player who failed to enter round one
                                    but has entered rounds two and three, thus
                                    building a streak of two.
                                </li>
                            </Styled.OrderedList>
                        </li>
                        <li>
                            Round Four Prize Draw &#40;x3 prizes available&#41;
                            <Styled.OrderedList>
                                <li>
                                    Each player who has entered the opening four
                                    rounds of Super 6 On Tour.
                                </li>
                                <li>
                                    Each player who failed to enter round one
                                    but has entered rounds two, three and four,
                                    thus building a streak of three.
                                </li>
                                <li>
                                    Each player who failed to enter rounds one
                                    and two but has entered rounds three and
                                    four, thus building a streak of two.
                                </li>
                            </Styled.OrderedList>
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    The prizes relating to each streaks prize draw are displayed
                    below:
                    <Styled.OrderedList>
                        <li>
                            Round Two Prize Draw:
                            <Styled.OrderedList>
                                <li>
                                    Sony PS5 Disc Console &#40;Streak of
                                    two&#41;
                                </li>
                            </Styled.OrderedList>
                        </li>
                        <li>
                            Round Three Prize Draw:
                            <Styled.OrderedList>
                                <li>
                                    Sony PS5 Disc Console &#40;Streak of
                                    two&#41;
                                </li>
                                <li>
                                    Camping Cinema Medium Garden Kit 80&quot;
                                    Projector &#40;Streak of three&#41;
                                </li>
                            </Styled.OrderedList>
                        </li>
                        <li>
                            Round Four Prize Draw:
                            <Styled.OrderedList>
                                <li>
                                    Sony PS5 Disc Console &#40;Streak of
                                    two&#41;
                                </li>
                                <li>
                                    Camping Cinema Medium Garden Kit 80&quot;
                                    Projector &#40;Streak of three&#41;
                                </li>
                                <li>
                                    Prizeshark &#163;2,500 Travel Voucher
                                    &#40;Streak of four&#41;
                                </li>
                            </Styled.OrderedList>
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    The Prize Draws will take place on the following dates:
                    <Styled.OrderedList>
                        <li>
                            Round Two Prize Draw &#45; Sunday 23rd June
                            &#40;Those who have entered two rounds in
                            succession&#41;
                        </li>
                        <li>
                            Round Three Prize Draw &#45; Thursday 27th June
                            &#40;Two draws, for those who have entered two and
                            three rounds in succession respectively&#41;
                        </li>
                        <li>
                            Round Four Prize Draw &#45; Thursday 4th July
                            &#40;Three draws, for those who have entered two,
                            three and four rounds in succession
                            respectively&#41;
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    Winners will be notified via onsite notification on the
                    Super 6 homepage.
                </li>
                <li>
                    To be eligible to win a prize as part of this competition,
                    you must be aged 18 years and over and registered as a
                    customer of Sky Betting &amp; Gaming.
                </li>
                <li>
                    This competition will run during for the duration of Super 6
                    On Tour, taking place during Euro 2024 and in each round
                    taking place between June 4th and July 5th.
                </li>
                <li>
                    Winners will be randomly allocated through a random
                    generator programme.
                </li>
                <li>
                    The prizes available to players is at the discretion of
                    Super 6. We reserve the right to credit the prize in cash.
                    For players using Euros, all prizes will be at an exchange
                    rate of &#163;1:&#8364;1.
                </li>
                <li>
                    Prize draws will take place at the discretion of Super 6. By
                    entering two or more Super 6 rounds in succession during
                    Super 6 On Tour, you will be automatically entered into a
                    Super 6 prize draw to win the respective prizes available.
                    These prize draw dates will also be announced by Super 6
                    through marketing communications.
                </li>
                <li>
                    Winners will be notified by web onsite notification and
                    email to the email address or phone call to the phone number
                    registered on their account. Delivery of the Prize will be
                    managed by Prizeshark, a third party supplier. Data will be
                    shared with Prizeshark solely to the extent necessary for
                    the purpose of fulfilment of the Prize. Prizeshark will also
                    be in touch via email or phone call. Customers are
                    responsible for ensuring the contact details on their
                    account are up to date.
                </li>
                <li>
                    If Prizeshark has been unable to contact the winner of the
                    Prize within 14 days of that game period or competition
                    ending, the winner shall forfeit the prize and Prizeshark
                    can expire the prize.
                </li>
                <li>
                    Once a winner has responded to the Promoter and verified
                    their details to the Promoter&apos;s satisfaction, the
                    winner will receive their prize within 28 days.
                </li>
                <li>
                    No entries on behalf of any third parties shall be accepted.
                </li>
                <li>
                    The Promoter Sky Betting and Gaming has the right, at their
                    reasonable discretion, to alter, amend or terminate this
                    competition acting reasonably and with prior notice.
                </li>
                <li>
                    If any provision or part&#45;provision of these terms and
                    conditions are or becomes invalid, illegal or unenforceable,
                    it shall be deemed modified to the minimum extent necessary
                    to make it valid, legal and enforceable. If such
                    modification is not possible, the relevant provision or
                    part&#45;provision shall be deemed deleted. Any modification
                    to or deletion of a provision or part&#45;provision under
                    this clause shall not affect the validity and enforceability
                    of the rest of these terms and conditions.
                </li>
                <li>
                    All general Sky Betting and Gaming rules and terms apply and
                    can be viewed{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://sbagmail.skybettingandgaming.com/a/hBmK92YB81v$ZB97$IyNug$Rr6n/sky7"
                    >
                        here.
                    </a>
                </li>
                <li>
                    Sky Betting and Gaming General Promotion Terms and
                    Conditions apply to this competition and can be viewed{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://sbagmail.skybettingandgaming.com/a/hBmK92YB81v$ZB97$IyNug$Rr6n/sky8"
                    >
                        here.
                    </a>
                </li>
                <li>
                    The Competition is co&#45;promoted by Sky UK Limited of
                    Grant Way, Isleworth, Middlesex, TW7 5QD
                    &#40;&quot;Sky&quot;&#41; and Hestview Limited &#40;trading
                    as Sky Games&#41; with a registered office address of One
                    Chamberlain Square Cs, Birmingham, United Kingdom, B3 3AX
                    &#40;the &apos;Promoter&apos;&#41;.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default Euros;
